<template>
  <div class="recharge">
    <div class="back">
      <span @click="back()" class="glyphicon glyphicon-arrow-left iconfont icon-jiantouxiangzuozuojiantou"></span>
      <img class="logo" src="@/assets/icon/chatgpt.jpg" />
      <span class="sub-title">{{ $t("充值") }}</span>
    </div>
    <div class="formBox">
      <el-row v-if="pagination" :gutter="20">
        <el-col :span="12" :offset="6">
          <el-input v-model="inputCDKEY" :placeholder="$t('请输入8位兑换码')">
            <el-button slot="append" @click="recharge()" class="rechargeBtn">
              {{ $t("确认兑换") }}
            </el-button>
          </el-input>
          <transition name="el-zoom-in-top">
            <span v-if="tipsCDKEY" class="tipsCDKEY">
              {{ tipsValue }}
            </span>
          </transition>
          <div class="separator">
            <hr />
            <div class="tip">or</div>
          </div>
          <div class="formBox pay">
            <div class="pay-1">
              <span class="title">{{ $t("购买数量") }}</span>
              <div class="input-number">
                <i class="icon el-icon-remove-outline" @click="buyNum--"></i>
                <span class="buyNum">{{ buyNum }} </span>
                <i class="icon el-icon-circle-plus-outline" @click="buyNum++"></i>
              </div>
            </div>
            <div class="pay-1">
              <span class="title">{{ $t("使用次数") }}</span
              ><span> {{ tokenNum }}</span>
            </div>
            <div class="pay-1">
              <span class="title">{{ $t("支付") }}</span
              ><span> ￥{{ payNum }}</span>
            </div>
            <div class="pay-1">
              <button class="continue" @click="next()">
                {{ $t("去支付") }}
              </button>
            </div>
            <!-- <div class="pay-2"><img :src="imageSrc" alt="Converted Image"></div> -->
          </div>
        </el-col>
      </el-row>
      <!-- 移动端页面 -->
      <div class="mobileBox" v-if="!pagination">
        <el-input v-model="inputCDKEY" :placeholder="$t('请输入8位兑换码')">
          <el-button slot="append" @click="recharge()" class="rechargeBtn">
            {{ $t("确认兑换") }}
          </el-button>
        </el-input>
        <transition name="el-zoom-in-top">
          <span v-if="tipsCDKEY" class="tipsCDKEY">
            {{ tipsValue }}
          </span>
        </transition>
        <div class="separator">
          <hr />
          <div class="tip">or</div>
        </div>
        <div class="formBox pay">
          <div class="pay-1">
            <span class="title">{{ $t("购买数量") }}</span>
            <!-- <el-input-number v-model="buyNum" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number> -->
            <div class="input-number">
              <i class="icon el-icon-remove-outline" @click="buyNum--"></i>
               <span class="buyNum">{{ buyNum }} </span>
              <i class="icon el-icon-circle-plus-outline" @click="buyNum++"></i>
            </div>
          </div>
          <div class="pay-1">
            <span class="title">{{ $t("使用次数") }}</span
            ><span> {{ tokenNum }}</span>
          </div>
          <div class="pay-1">
            <span class="title">{{ $t("支付") }}</span
            ><span> ￥{{ payNum }}</span>
          </div>
          <div class="pay-1">
            <button class="continue" @click="next()">{{ $t("去支付") }}</button>
          </div>
          <!-- <div class="pay-2"><img :src="imageSrc" alt="Converted Image"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { coursesExchange } from "../../config/api";
export default {
  name: "recharge",
  data() {
    return {
      inputCDKEY: "",
      tipsValue: "",
      tipsCDKEY: false,
      pagination: true,
      tokenNum: 100,
      payNum: 29.9,
      buyNum: 1
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    recharge() {
      let inputCDKEYStatus = !this.inputCDKEY || this.inputCDKEY.length !== 8;
      if (inputCDKEYStatus) return this.showTips(inputCDKEYStatus);
      else return this.courses();
    },
    courses() {
      coursesExchange({ code: this.inputCDKEY }).then(res => {
        let detail = res.data.ret;
        let status = res.status;
        if (!detail) {
          let no = res.data.no;
          let usage = res.data.usage;
          let validity = res.data.validity;
          this.$router.push({
            path: "rechargeDetail",
            query: { detail, status, no, usage, validity }
          });
        } else {
          this.showTips(detail);
        }
        // this.$router.push({
        //   path: "rechargeDetail",
        //   query: { detail, status }
        // });
      });
    },
    showTips(val) {
      console.log(val);
      this.tipsCDKEY = true;
      setTimeout(() => {
        this.tipsCDKEY = false;
      }, 1500);
      if (val) this.tipsValue = this.$t("请输入正确的兑换码");
      if (val === 1) this.tipsValue = this.$t("兑换码已使用");
      if (val === 2) this.tipsValue = this.$t("兑换码不存在");
    },
    handleResize() {
      this.pagination = window.innerWidth > 768;
    },
    handleChange(value) {
      console.log(value);
    },
    next() {
      this.$router.push({
        name: "pay",
        query: {
          buyNum: this.buyNum
        }
      });
    }
  },
  watch: {
    buyNum(newVal) {
      this.payNum = (29.9 * newVal).toFixed(1);
      this.tokenNum = 100 * newVal;
      if (newVal<=0){
        this.buyNum = 1
      }
    }
  }
};
</script>
<style lang="less" scoped>
.recharge{
  padding: 5% 5% 0 5%;
  .back{
    display: flex;
    align-items: center;
    .glyphicon-arrow-left {
      color: hsla(0,0%,10%,.6);
      margin-right: 2rem;
      &:hover {
        cursor: pointer;
      }
    }

    .logo {
      width: 30px;
      height: 30px;
    }

    .sub-title {
      color: hsla(0,0%,10%,.6);
      font-weight: 500;
      font-size: 16px;
      margin-left: 1rem;
    }
  }
  .formBox{
    margin-top: 8rem;
    .separator{
      position: relative;
      hr{
        background-color: #f1f2f2;
      }
      .tip{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        background-color: #fff;
        color: #5d6565;
        font-size: 13px;
        padding: 0 1rem;
        font-weight: 450;
        position: absolute;
      }
    }
    .el-input-group {
      margin-bottom: 4rem;
    }
    .pay{
      margin-top: 4rem;
      .pay-1,.pay-2{
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        .title {
          color: #a3a3a3;
        }
        .continue {
          background-color: #10a37f;
          color: white;
          border: 0px;
          outline: none;
          border-radius: 3px;
          height: 52px;
          width: 100% !important;
          margin-bottom: 3rem;
        }
      }
      .pay-2{
        justify-content: center;
        img {
          width: 240px;
          height: 240px;
        }
      }
    }
    .input-number {
      .buyNum{
        margin: 0 1rem;
      }
      .icon:hover {
        color: #10a37f;
        cursor: pointer;
      }
    }
  }
  .tipsCDKEY{
    font-size: 12px;
    color: #f56c6c;
  }
}
</style>
